<template>
  <div style="overflow: hidden">
    <control-navbar/>
    <page-header :title="locale.lost"/>
    <br/>
    <div class="container" v-if="lostTrx">
      <div class="row">
        <div class="col-12">
          <div id="voucherPrint">
            <div v-for="(data, i) in lostTrx.data" :key="i">
              <h2>{{ data.date }}</h2>
              <div v-for="(log, j) in data.myLogInvoiceResponses" :key="`${i}_${j}`">
                <div v-for="(v, k) in log.logDetails" :key="`${i}_${j}_${k}`">
                  <div
                      class="row"
                      style="padding: 5% 10% 10% 10%"
                  >
                    <div class="col-2 voucher-image">
                      <img
                          class="voucher-result"
                          :src="v.pictureUrl"
                          :alt="v.picture"
                      />
                    </div>
                    <div class="col-8 " style="text-align: start">
                      <p v-if="v.viewType === 6 || v.viewType === 19 || v.viewType === 21">{{ v.service }}</p>
                      <p>{{ v.denomination }}</p>
                      <p>
                        <strong>{{ locale.transaction_id }} </strong
                        >{{ v.transactionId }}
                      </p>
                      <p v-if="v.viewType !== 19">
                        <strong>{{ locale.amount }} </strong>{{ v.amount }}
                      </p>
                      <p v-if="v.viewType === 16">
                        <strong>{{ locale.fees }} </strong>{{ v.fees }}
                      </p>
                      <p v-if="v.balance">
                        <strong>{{ locale.balance }} </strong>{{ v.balance }}
                      </p>
                      <p v-if="v.viewType === 4 ||  v.viewType === 5 ||
                           v.viewType === 6 ||  v.viewType === 10 ||
                           v.viewType === 11 || v.viewType === 12 ||
                           v.viewType === 13 || v.viewType === 14 ||
                           v.viewType === 16 || v.viewType === 19 ||
                           v.viewType === 21">
                        <strong v-if="v.viewType === 11">{{ locale.plate_number }} </strong>
                        <strong v-if="v.viewType === 12">{{ locale.id }} </strong>
                        <strong v-if="v.viewType === 16">{{ locale.ltn }} </strong>
                        <strong v-else>{{ locale.phone_number }} </strong>
                        {{ v.phone }}
                      </p>

                      <p v-if=" v.viewType ===  1 || v.viewType ===  2|| v.viewType ===  3|| v.viewType ===  4||
                            v.viewType === 9|| v.viewType ===  11||
                            v.viewType === 13 || v.viewType ===  14|| v.viewType ===  15||
                            v.viewType === 17|| v.viewType ===  18|| v.viewType ===  20">
                        <strong v-if="v.viewType === 17|| v.viewType ===  18">{{ locale.description }} </strong>
                        <strong v-if="v.viewType === 11">{{ locale.district }} </strong>
                        <strong v-if="v.viewType === 15">{{ locale.vin }} </strong>
                        <strong v-else>{{ locale.sn }} </strong>

                        <span
                            v-if="v.voided && v.viewType !== 11 && v.viewType !== 17 && v.viewType !== 18">*****</span>
                        <span v-else>{{ v.serial }}</span>
                      </p>

                      <p v-if="v.viewType === 1 || v.viewType === 2 || v.viewType === 3 || v.viewType === 4 || v.viewType === 9 || v.viewType === 11 || v.viewType === 13 || v.viewType === 4 || v.viewType === 18 || v.viewType === 20">
                        <strong v-if="v.viewType === 2|| v.viewType ===  3">{{ locale.password }} </strong>
                        <strong v-if="v.viewType === 20">{{ locale.promo_code }} </strong>
                        <strong v-if="v.viewType ===  18">{{ locale.acc_accountName }} </strong>
                        <strong v-else>{{ locale.secret_code }} </strong>
                        <span v-if="v.voided && v.viewType !== 18">****</span>
                        <span v-else>{{ v.formattedSecret }}</span>
                      </p>
                      <p v-if="v.viewType === 1 || v.viewType === 3 || v.viewType === 11 || v.viewType === 15">
                        <strong v-if="v.viewType === 11">{{ locale.date }} </strong>
                        <strong v-else>{{ locale.expiry_date }} </strong>

                        <span>{{ v.expiry }}</span>
                      </p>
                      <p v-if="v.viewType === 17 || v.viewType === 18">
                        <strong>{{ locale.status }} </strong>

                        <span>{{ v.secret }}</span>
                      </p>

                      <p v-if="v.viewType === 10 || v.viewType === 11 || v.viewType === 15">
                        <strong v-if="v.viewType === 15"><a :href="v.secret">{{ locale.view_report }} </a></strong>
                        <strong v-else><a :href="v.secret">{{ locale.view_receipt }}</a> </strong>

                      </p>
                      <p v-if="fromVoid">
                        <strong>{{ locale.from_void }}</strong>
                      </p>
                      <p v-if="voided">
                        <strong>{{ locale.voided }}</strong>
                      </p>


                    </div>
                    <div class="col-2">
                      <div class="col-12"> {{ v.time }}</div>
                    </div>
                    <div class="col-12">
                      <info-button v-if="v.help" :help="v.help"/>

                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ControlNavbar from "../components/ControlNavbar.vue";
import {LocaleArabic, LocaleEnglish} from "@/scripts/Locale";

export default {
  name: "LostTRX",
  components: {
    ControlNavbar,
  },
  data() {
    return {
      lostTrx: null,
      locale: null,
    };
  },
  methods: {
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") === "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },
  },
  created: function () {
    if (this.$route.params.data) {
      //  ok
      this.lostTrx = this.$route.params.data;
      this.$session.set("lost", this.$route.params.data);
    } else {
      this.lostTrx = this.$session.get("lost");
      //  undefined
    }
    console.log(this.lostTrx);
    this.checkLocal();
  },
};
</script>

<style lang="scss" scoped>
.voucher-image {
  img {
    width: inherit;
  }
}

.infoButon {
  width: 100%;
}
</style>